import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { TextField, Grid, Link, MenuItem, Container, Collapse, Typography, Button } from '@material-ui/core';
import { loginAction, handleSelectClientAction } from './actions/loginActions';
import { Form } from '../Generic';
import { push } from 'connected-react-router';
import Password from '../Users/MyPages/ChangePassword/Password';

class LoginForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			selectedLanguage: this.props.selectedLanguage,
			recovery: this.props.recovery ? this.props.recovery : false,
			recovery_token: this.props.recovery_token ?? null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.recovery !== this.props.recovery) {
			this.setState({
				recovery: this.props.recovery,
				recovery_token: this.props.recovery_token,
			})
		} 
	}

	handleUsername = (e) => {
		this.setState({
			username: e.target.value,
		});
	};
	handlePassword = (e) => {
		this.setState({
			password: e.target.value,
		});
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleRecovery = () => {
		this.setState({
			recovery: !this.state.recovery,
			recovery_token: null,
		});
		this.props.push('/login');
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'login',
			title: '',
			subtitle: '',
			helperText: '',
			button_label: translate('generic_button_sign_in'),
			button_loading_label: translate('login_form_loading_button'),
			fields: [
				{
					id: 'login_email',
					name: 'login_email',
					label: translate('login_form_field_email'),
					type: 'input',
					props: {
						maxLength: 64,
					},
					validate: {
						required: true,
					},
				},
				{
					id: 'login_password',
					name: 'login_password',
					label: translate('login_form_field_password'),
					type: 'password',
					validate: {
						required: true,
					},
				},
				{
					id: 'deviceid',
					name: 'deviceid',
					label: '',
					type: 'hidden',
				},
				{
					id: 'language',
					name: 'language',
					label: '',
					type: 'hidden',
					initialvalue: this.props.selectedLanguage,
				},
			],
		};

		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};

	handleSubmitButton = (form) => {
		if (typeof this.props.handleSubmitAction !== 'undefined') {
			this.props.handleSubmitAction(form);
		} else {
			this.props.loginAction(form);
		}
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		return (
			<React.Fragment>
				{this.props.login.platform === 'backoffice' ? (
					<Container component="main" maxWidth="sm" className="generic-form-main-no-margin">
						<TextField
							fullWidth={true}
							select
							label={this.props.translate('login_select_client_backoffice')}
							value={this.props.login.client !== null && this.props.login.client.id}
							onChange={(value) =>
								this.props.handleSelectClientAction(
									this.props.settings.clients.find((client) => client.id === value.target.value),
									'backoffice'
								)
							}
							margin="normal"
							variant="outlined"
						>
							{this.props.settings.clients.map((client) => (
								<MenuItem key={client.id} value={client.id}>
									{client.name}
								</MenuItem>
							))}
						</TextField>
					</Container>
				) : null}
				<Collapse in={!this.state.recovery}>
					{this.props.settings.feature.self_registration &&
						this.props.login.platform == 'frontoffice' &&
						!this.props.disableSelfRegistration && (
							<Container component="main" maxWidth="sm" className="">
								<Typography align="center" variant="subtitle2" component="p">
									{translate('user_create_new_account_info')}
								</Typography>
								<Button
									fullWidth
									variant="contained"
									color={'primary'}
									className="login-register-content-button"
									onClick={() => {
										this.props.push('/register');
									}}
								>
									{translate('user_register_new_patient')}
								</Button>
							</Container>
						)}
					{this.props.settings.feature.self_registration && this.props.login.platform == 'frontoffice' && (
						<Typography align="center" variant="subtitle2">
							{translate('user_login_info')}
						</Typography>
					)}
					<Form
						form={configuration.structure.form}
						formData={configuration.structure}
						initialValues={configuration.initialValues}
						handleSubmit={() => this.handleSubmitButton(configuration.structure.form)}
						actionDisabled={this.props.login.client && this.props.login.client.id === 0}
					/>
					{this.props.hideLinks ? null : (
						<Grid container>
							<Grid item xs={12} md={12}>
								<Link
									href="#"
									variant="body2"
									onClick={() => {
										this.props.handleSelectClientAction(null, null);
										this.props.push('/login');
									}}
								>
									&crarr; {translate('login_form_back_to_client_selection')}
								</Link>
							</Grid>
						</Grid>
					)}
				</Collapse>
				{this.props.hideLinks ? null : (
					<Grid container>
						<Grid item xs={12} md={12}>
							<Link
								href="#"
								variant="body2"
								onClick={() => {
									this.handleRecovery();
								}}
							>
								&crarr; {this.state.recovery ? translate('login_go_back') : translate('login_password_recovery')}
							</Link>
						</Grid>
					</Grid>
				)}
				<Collapse in={this.state.recovery}>
					<Password hiddeTitles={true} handleRecovery={this.handleRecovery} recovery_token={this.state.recovery_token} />
				</Collapse>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	login: state.login,
	settings: state.settings,
});

export default connect(mapStateToProps, { push, loginAction, handleSelectClientAction })(LoginForm);
