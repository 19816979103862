import { isChrome } from 'react-device-detect';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

function formatDate(date) {
	return date ? date.slice(0, -3) : date;
}

function formatDateRemoveTime(date) {
	return date ? date.slice(0, -8) : date;
}

function dateToString(date, removeTime = false) {
	let result;

	if (removeTime) {
		result = date.toISOString().replace('T', ' ').replace('Z', '');
		result = result.split(' ')[0];
	} else {
		result = date.toISOString().replace('T', ' ').replace('Z', '');
	}

	return result;
}

function parseDateOffset(timestampStr) {
	return new Date(timestampStr.getTime() - timestampStr.getTimezoneOffset() * 60 * 1000);
}

function parseDateUTC(date) {
	var b = date.split(/\D/);
	return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
}

function bytesToMegaBytes(bytes) {
	return bytes / (1024 * 1024);
}

function parseDate(date) {
	var b = date.split(/\D/);
	return new Date(b[0], --b[1], b[2], b[3], b[4], b[5]);
}

function setDateTimeToDate(date, dateTime) {
	date.setHours(dateTime.getHours());
	date.setMinutes(dateTime.getMinutes());
	date.setSeconds(dateTime.getSeconds());

	return date;
}

function addMinutesToDate(date, minutes) {
	const newDate = new Date(date.getTime());

	newDate.setMinutes(date.getMinutes() + minutes);

	return newDate;
}

function formatTableDate(date) {
	return date !== null ? date.slice(0, -3) : '';
}

function luhn(str) {
	let v = 0;
	let sum = 0;

	for (var i = 0; i < str.length; i++) {
		v = parseInt(str[i]);
		v *= 2 - (i % 2);
		if (v > 9) {
			v -= 9;
		}
		sum += v;
	}

	return parseInt(Math.ceil(sum / 10) * 10 - sum);
}

function testDate(year, month, day) {
	var validDate = false;
	try {
		let date = new Date(year + '-' + month + '-' + day).toISOString().slice(0, 10);
		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}

		var splitDate = date.split('-');

		validDate = !(
			parseInt(splitDate[0]) !== parseInt(year) ||
			parseInt(splitDate[1]) !== parseInt(month) ||
			parseInt(splitDate[2]) !== parseInt(day)
		);
	} catch (err) {
		// Pass.
		console.log('catch');
		console.log(err);
		return false;
	}

	return validDate;
}

function swedish_ssn(value) {
	if (typeof value === 'undefined' || value === null || value.length == 0) {
		return true;
	}
	if (!Number.isInteger(value) && !typeof value === 'string') {
		return false;
	}

	var reg = /^(\d{2}){0,1}(\d{2})(\d{2})(\d{2})([\-|\+]{0,1})?(\d{3})(\d{0,1})$/gm;

	var match = reg.exec(value);

	if (typeof match === 'undefined' || match === null || match[0].split('').length < 7) {
		return false;
	}

	let century = match[1];
	let year = match[2];
	let month = match[3];
	let day = match[4];
	let sep = match[5];
	let num = match[6];
	let check = match[7];

	var valid = luhn(year + month + day + num) === parseInt(check);
	if (valid && testDate(century + year, month, day)) {
		return true;
	}

	return valid && testDate(century + year, month, day) ? true : false;
}

function openPdfFIle(fileURL) {
	if (isMobileSize()) {
		window.location.assign(fileURL);
	} else {
		if (isChrome) {
			window.open(fileURL + '#view=fit');
		} else {
			window.open(fileURL);
		}
	}
}

function isMobileSize() {
	return (window.orientation === (90 || -90) && window.screen.height < 960) || (window.orientation === 0 && window.screen.width < 960);
}

// set up the possible functions:
const my_ssn_validations = {
	swedish_ssn: (value) => {
		return swedish_ssn(value);
	},
};
// execute the one specified in the 'funcToRun' variable:
//   my_ssn_validations[funcToRun]();

function escapeRegExp(string) {
	return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
	return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function printDocument(file_name, element_id, callback, download = false, loading) {
	const input = document.getElementById(element_id);

	domtoimage
		.toPng(input)
		.then(function (dataUrl) {
			const pdf = new jsPDF('p', 'px', 'a4');
			var pdfWidth = pdf.internal.pageSize.getWidth();
			var pdfHeight = pdf.internal.pageSize.getHeight();
			var marginX = 10;
			var maxWidth = pdfWidth - marginX * 2;
			var ratio = pdfWidth / (input.offsetWidth - marginX * 2);

			var heightLeft = input.offsetHeight * ratio;

			var position = 0;
			pdf.addImage(dataUrl, 'PNG', 10, 10, maxWidth, input.offsetHeight * ratio);
			heightLeft -= pdfHeight - 10;
			while (heightLeft >= 0) {
				position = heightLeft - input.offsetHeight * ratio;
				console.log(position);
				pdf.addPage();
				pdf.addImage(dataUrl, 'PNG', 10, position, maxWidth, input.offsetHeight * ratio);
				heightLeft -= pdfHeight;
			}
			// pdf.output('dataurlnewwindow');
			if (callback) {
				callback(pdf.output('blob', { filename: file_name + '.pdf' }));
			}
			if (download) {
				pdf.save(file_name ? file_name + '.pdf' : 'selfcare.pdf');
			}
			if (loading) {
				loading();
			}
		})
		.catch(function (error) {
			console.error('oops, something went wrong!', error);
		});
}

function convertHex(hexCode, opacity) {
	var hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}

	var r = parseInt(hex.substring(0, 2), 16),
		g = parseInt(hex.substring(2, 4), 16),
		b = parseInt(hex.substring(4, 6), 16);

	return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
}

function LightenDarkenColor(col, amt) {
	var usePound = false;

	if (col[0] == '#') {
		col = col.slice(1);
		usePound = true;
	}

	var num = parseInt(col, 16);

	var r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	var b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	var g = (num & 0x0000ff) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

function exitElementFullscreen() {
	if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else {
			console.log('Fullscreen API is not supported.');
		}
	}
}

export {
	parseDateOffset,
	setDateTimeToDate,
	dateToString,
	parseDate,
	parseDateUTC,
	addMinutesToDate,
	formatDate,
	formatDateRemoveTime,
	formatTableDate,
	swedish_ssn,
	openPdfFIle,
	isMobileSize,
	replaceAll,
	printDocument,
	convertHex,
	LightenDarkenColor,
	bytesToMegaBytes,
	my_ssn_validations,
	exitElementFullscreen
};
