import axios from 'axios';
import eventsService from '../Events';
import { getTranslate } from 'react-localize-redux';
import { store } from '../../store.js';
import { logoutAction } from '../../components/Login/actions/loginActions';

function handleError(error, alert = 'alert', handle_error) {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	if (state.login.login && error.response.status === 401) {
		store.dispatch(logoutAction(true, state.login.type !== 'meeting', translate('inactivity_logout_info_message')));
	} else if (typeof handle_error === 'function') {
		handle_error(error, () => {
			eventsService.triggerEvent(alert, { type: 'error', message: error.response.data.message, context: error.response.data.context });
		});
	} else {
		eventsService.triggerEvent(alert, { type: 'error', message: error.response.data.message, context: error.response.data.context });
	}
	throw new Error(error.response.data.message);
}

const apiRequest = ({ method, url, params = {}, data = false, headers = {}, alert = 'alert', success_message = '', handle_error = false }) => {
	return axios({
		method,
		url,
		params,
		...(data ? { data } : {}),
		headers,
	})
		.then((response) => {
			if (success_message) {
				eventsService.triggerEvent('alert', { type: 'success', message: success_message });
			}

			return response;
		})
		.catch((error) => handleError(error, alert, handle_error));
};

export { apiRequest, handleError };
