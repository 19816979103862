import axios from 'axios';
import { store } from '../../../store.js';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import {
	FETCH_ACTIVITIES,
	FETCH_ACTIVITY,
	POST_ACTIVITY,
	DELETE_ACTIVITY,
	FETCH_ACTIVITY_EVENTS,
	FETCH_ACTIVITY_ATTENDEES,
	UPDATE_ACTIVITY_EVENTS,
	REMOVE_ACTIVITY_ATTENDEE,
	REMOVE_ACTIVITY_EVENT,
	INVITATION_ACTIVITY,
	CLEAR_ACTIVITY,
} from './types';
import { HANDLE_TABLE_LOADING } from '../../Generic/actions/types';
import { convertToFormData, addDelayAction, handleLoadingAction } from '../../Generic/actions/genericActions';
import eventsService from '../../../helpers/Events';
import { parseDateOffset } from '../../../helpers/functions/functions';
import { setTableCountAction } from '../../Generic/actions/tableActions';

export const fetchActivitiesAction = (config, pagination = {}, filters = {}) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var params = { ...pagination, ...filters };

	var columns = [];

	config.map((field) => {
		if (field.options.renderOnExpand) {
			field.options.display = 'excluded';
		}
		columns.push({
			name: field.label,
			label: translate('table_column_' + field.label),
			options: {
				...field.options,
			},
		});
	});

	dispatch({
		type: FETCH_ACTIVITIES,
		payload: {
			columns: columns,
			data: [],
			total_count: 0,
		},
	});

	dispatch({
		type: HANDLE_TABLE_LOADING,
		payload: true,
	});

	setTimeout(() => {
		axios
			.get(state.config.apihost + `/activities`, {
				params,
			})
			.then((res) => {
				dispatch({
					type: HANDLE_TABLE_LOADING,
					payload: false,
				});

				dispatch({
					type: FETCH_ACTIVITIES,
					payload: {
						columns: columns,
						data: res.status == 204 ? [] : res.data.data,
						total_count: res.status == 204 ? 0 : res.data.total_count,
					},
				});

				dispatch(setTableCountAction(res.data.total_count));
			})
			.catch((err) => {
				dispatch({
					type: HANDLE_TABLE_LOADING,
					payload: false,
				});

				console.log(err);
				console.log('Error fetching data.');
			});
	}, 1000);
};

export const fetchActivityAction = (id, loading = true) => (dispatch) => {
	if (loading) {
		dispatch(handleLoadingAction(true));
	}

	const state = store.getState();

	axios
		.get(state.config.apihost + `/activities/` + id)
		.then((res) => {
			dispatch({
				type: FETCH_ACTIVITY,
				payload: res.data,
			});

			if (loading) {
				dispatch(handleLoadingAction(false));
			}
		})
		.catch((error) => {
			if (loading) {
				dispatch(handleLoadingAction(false));
			}
			console.log(error);
		});
};

export const fetchActivitybyTokenAction = (token, loading = true) => (dispatch) => {
	if (loading) {
		dispatch(handleLoadingAction(true));
	}

	const state = store.getState();

	axios
		.get(state.config.apihost + `/activities/token/` + token)
		.then((res) => {
			dispatch({
				type: FETCH_ACTIVITY,
				payload: res.data,
			});

			if (loading) {
				dispatch(handleLoadingAction(false));
			}
			
			dispatch(push('/activity/' + res.data.id));
		})
		.catch((error) => {
			if (loading) {
				dispatch(handleLoadingAction(false));
			}
			console.log(error);
			dispatch(push('/dashboard'));
		});
};


export const postActivityAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.apihost + `/activities`, data)
		.then((res) => {
			dispatch({
				type: POST_ACTIVITY,
				payload: res.data,
			});
			// console.log(events);
			// events.forEach(event => {
			// 	dispatch(postActivityEvent(event, res.data.id));
			// });

			dispatch(push('/activity/' + res.data.id + '/edit'));

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchActivityAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.patch(state.config.apihost + `/activities/` + state.activities.activity.id, data)
		.then((res) => {
			dispatch({
				type: FETCH_ACTIVITY,
				payload: res.data,
			});
			// console.log(events);
			// events.forEach(event => {
			// 	dispatch(postActivityEvent(event, res.data.id));
			// });

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_activity') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const deleteActivityAction = (activity_id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/activities/` + activity_id)
		.then((res) => {
			dispatch({
				type: DELETE_ACTIVITY,
				payload: res.data,
			});

			dispatch(
				addDelayAction(push('/activities'), () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_activity') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postActivityEventAction = (event, updateEventsStateId) => (dispatch) => {
	const state = store.getState();

	event.start = new Date(event.start_date.setTime(event.start_time.getTime()));
	event.end = new Date(event.start.getTime() + Number(event.duration) * 60000);

	var start = parseDateOffset(event.start).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = parseDateOffset(event.end).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var body = new FormData();

	body.append('start', start);
	body.append('end', end);
	body.append('location', event.location);

	axios
		.post(state.config.apihost + `/activities/` + state.activities.activity.id + `/events`, body)
		.then((res) => {
			dispatch({
				type: FETCH_ACTIVITY_EVENTS,
				payload: res.data[0],
			});

			updateEventsStateId(res.data[0].id);

			// eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity_event') })
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchActivityEventAction = (event, updateEventsToUpdateState) => (dispatch) => {
	const state = store.getState();

	event.start_time.setDate(event.start_date.getDate());

	event.start = new Date(event.start_date);
	event.start.setTime(event.start_time.getTime());
	event.end = new Date(event.start.getTime() + Number(event.duration) * 60000);

	var start = parseDateOffset(event.start).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = parseDateOffset(event.end).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var body = new FormData();

	body.append('start', start);
	body.append('end', end);
	body.append('location', event.location);

	axios
		.patch(state.config.apihost + `/activities/` + state.activities.activity.id + `/events/` + event.id, body)
		.then((res) => {
			dispatch({
				type: UPDATE_ACTIVITY_EVENTS,
				payload: res.data,
			});

			updateEventsToUpdateState(res.data.id);

			// eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity_event') })
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const removeActivityEventAction = (event_id, removeActivityEventFromState) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/activities/` + state.activities.activity.id + `/events/` + event_id)
		.then((res) => {
			dispatch({
				type: REMOVE_ACTIVITY_EVENT,
				payload: res.data,
			});

			removeActivityEventFromState(res.data.id);

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_activity_event') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const inviteActivityAttendeeAction = (attendee_id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('users_id', attendee_id);

	axios
		.post(state.config.apihost + `/activities/` + state.activities.activity.id + `/attendees`, body)
		.then((res) => {
			dispatch({
				type: FETCH_ACTIVITY_ATTENDEES,
				payload: res.data,
			});

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_activity_invite_attendees') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const removeActivityAttendeeAction = (attendee_id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/activities/` + state.activities.activity.id + `/attendees/` + attendee_id)
		.then((res) => {
			dispatch({
				type: REMOVE_ACTIVITY_ATTENDEE,
				payload: res.data,
			});

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_activity_invite_attendees') });
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const notifyActivityAttendeeAction = (id, notify) => (dispatch) => {

	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.post(state.config.apihost + `/activities/` + id + `/notify/`, 
		{
			notify: notify,
		})
		.then((res) => {

			dispatch({
				type: INVITATION_ACTIVITY,
				payload: {
					attendees : res.data.attendees,
				},
			});

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_activity_invition') });

		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const clearActivityAction = () => (dispatch) => {
	dispatch({
		type: CLEAR_ACTIVITY,
	});
};
