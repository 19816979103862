import { createTheme, responsiveFontSizes, makeStyles } from '@material-ui/core/styles';
import classes from './classes';
import NettoWebFont from './fonts/NettoWeb.woff';
import RobotoRegularFont from './fonts/Roboto-Regular.ttf';
import RobotoBoldFont from './fonts/Roboto-Bold.ttf';
import WorkSansFont from './fonts/work-sans-v11-latin-regular.woff';
import { isMobileSize, LightenDarkenColor } from '../helpers/functions/functions';

const NettoWeb = {
	fontFamily: 'NettoWeb',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${NettoWebFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const RobotoRegular = {
	fontFamily: 'RobotoRegular',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${RobotoRegularFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const RobotoBold = {
	fontFamily: 'RobotoBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${RobotoBoldFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const WorkSans = {
	fontFamily: 'WorkSans',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${WorkSansFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const allowedFontFamilies = ['NettoWeb', 'RobotoRegular', 'RobotoBold', 'WorkSans'];

export const theme = (settings, client) =>
	responsiveFontSizes(
		createTheme({
			palette: {
				primary: {
					main:
						client !== null &&
						typeof client.id !== 'undefined' &&
						client.id != '0' &&
						settings.clients.find((element) => element.id == client.id).primary_color
							? settings.clients.find((element) => element.id == client.id).primary_color
							: typeof settings.site !== 'undefined' && settings.site.color_primary
							? settings.site.color_primary
							: '#00A99D',
					contrastText: '#FFFFFF',
					light:
						client !== null &&
						typeof client.id !== 'undefined' &&
						client.id != '0' &&
						settings.clients.find((element) => element.id == client.id).primary_color
							? LightenDarkenColor(settings.clients.find((element) => element.id == client.id).primary_color, 70)
							: typeof settings.site !== 'undefined' && settings.site.color_primary
							? LightenDarkenColor(settings.site.color_primary, 70)
							: LightenDarkenColor('#00A99D', 70),
				},
				secondary: {
					main:
						client !== null &&
						typeof client.id !== 'undefined' &&
						client.id != '0' &&
						settings.clients.find((element) => element.id == client.id).secondary_color
							? settings.clients.find((element) => element.id == client.id).secondary_color
							: typeof settings.site !== 'undefined' && settings.site.color_secondary
							? settings.site.color_secondary
							: '#17a2b8',
					contrastText: '#FFFFFF',
					light:
						client !== null &&
						typeof client.id !== 'undefined' &&
						client.id != '0' &&
						settings.clients.find((element) => element.id == client.id).color_secondary
							? LightenDarkenColor(settings.clients.find((element) => element.id == client.id).color_secondary, 70)
							: typeof settings.site !== 'undefined' && settings.site.color_secondary
							? LightenDarkenColor(settings.site.color_secondary, 70)
							: LightenDarkenColor('#00A99D', 70),
				},
			},
			video: {
				primary: {
					main:
						typeof settings.styling !== 'undefined' && settings.styling.video_preview_primary_color
							? settings.styling.video_preview_primary_color
							: typeof settings.site !== 'undefined' && settings.site.color_primary
							? settings.site.color_primary
							: '#FFFFFF',
				},
				secondary: {
					main:
						typeof settings.styling !== 'undefined' && settings.styling.video_preview_secondary_color
							? settings.styling.video_preview_secondary_color
							: typeof settings.site !== 'undefined' && settings.site.color_secondary
							? settings.site.color_secondary
							: '#FFFFFF',
				},
			},
			typography: {
				...(typeof settings.styling !== 'undefined' &&
				settings.styling.font_family_other_sizes &&
				settings.styling.font_family_other_sizes.length > 0 &&
				allowedFontFamilies.includes(settings.styling.font_family_other_sizes)
					? {
							fontFamily: settings.styling.font_family_other_sizes,
					  }
					: {}),
				useNextVariants: true,
				...(typeof settings.styling !== 'undefined' &&
				settings.styling.font_family_h1 &&
				settings.styling.font_family_h1.length > 0 &&
				allowedFontFamilies.includes(settings.styling.font_family_h1)
					? {
							h1: {
								fontFamily: settings.styling.font_family_h1,
							},
					  }
					: {}),

				h2: {
					color:
						client !== null &&
						typeof client.id !== 'undefined' &&
						client.id != '0' &&
						settings.clients.find((element) => element.id == client.id).primary_color
							? settings.clients.find((element) => element.id == client.id).primary_color
							: typeof settings.site !== 'undefined' && settings.site.color_primary
							? settings.site.color_primary
							: '#00A99D',
					...(typeof settings.styling !== 'undefined' &&
					settings.styling.font_family_h2 &&
					settings.styling.font_family_h2.length > 0 &&
					allowedFontFamilies.includes(settings.styling.font_family_h2)
						? {
								fontFamily: settings.styling.font_family_h2,
						  }
						: {}),
				},
				...(typeof settings.styling !== 'undefined' &&
				settings.styling.font_family_h3 &&
				settings.styling.font_family_h3.length > 0 &&
				allowedFontFamilies.includes(settings.styling.font_family_h3)
					? {
							h3: {
								fontFamily: settings.styling.font_family_h3,
							},
					  }
					: {}),
				...(typeof settings.styling !== 'undefined' &&
				settings.styling.font_family_h4 &&
				settings.styling.font_family_h4.length > 0 &&
				allowedFontFamilies.includes(settings.styling.font_family_h4)
					? {
							h4: {
								fontFamily: settings.styling.font_family_h4,
							},
					  }
					: {}),
			},
			overrides: {
				MuiCssBaseline: {
					'@global': {
						'@font-face': [NettoWeb, RobotoRegular, RobotoBold, WorkSans],
					},
				},
				MuiButton: {
					root: {
						...(typeof settings.styling !== 'undefined' &&
						settings.styling.font_family_button &&
						settings.styling.font_family_button.length > 0 &&
						allowedFontFamilies.includes(settings.styling.font_family_button)
							? {
									fontFamily:
										typeof settings.styling !== 'undefined' &&
										settings.styling.font_family_button &&
										allowedFontFamilies.includes(settings.styling.font_family_button)
											? settings.styling.font_family_button
											: '"Roboto", "Helvetica", "Arial", sans-serif',
							  }
							: {}),
						'&$disabled': {
							cursor: 'not-allowed',
							pointerEvents: 'unset',
						},
					},
					...(typeof settings.styling !== 'undefined' &&
					settings.styling.button_primary_hover_color &&
					settings.styling.button_primary_hover_color.length > 0
						? {
								containedPrimary: {
									'&:hover': {
										backgroundColor: settings.styling.button_primary_hover_color,
										// backgroundColor: convertHex(settings.clients.find((element) => element.id == client.id).primary_color, 50),
									},
								},
						  }
						: {}),
					...(typeof settings.styling !== 'undefined' &&
					settings.styling.button_secondary_hover_color &&
					settings.styling.button_secondary_hover_color.length > 0
						? {
								containedSecondary: {
									'&:hover': {
										backgroundColor: settings.styling.button_secondary_hover_color,
										// backgroundColor: convertHex(settings.clients.find((element) => element.id == client.id).secondary_color, 50),
									},
								},
						  }
						: {}),
				},
				...(typeof settings.styling !== 'undefined' && settings.styling.link_hover_color && settings.styling.link_hover_color.length > 0
					? {
							MuiLink: {
								root: {
									'&:hover': {
										color: settings.styling.link_hover_color,
									},
								},
							},
					  }
					: {}),
				...(isMobileSize() && {
					MuiTableRow: {
						hover: {
							'&:hover.muitable-expanded-top-row': {
								backgroundColor:
									client !== null &&
									typeof client.id !== 'undefined' &&
									client.id != '0' &&
									settings.clients.find((element) => element.id == client.id).primary_color
										? LightenDarkenColor(settings.clients.find((element) => element.id == client.id).primary_color, 70) +
										  ' !important'
										: typeof settings.site !== 'undefined' && settings.site.color_primary
										? LightenDarkenColor(settings.site.color_primary, 70) + ' !important'
										: LightenDarkenColor('#00A99D', 70) + ' !important',
							},
							'&:hover:not(.muitable-expanded-top-row)': {
								backgroundColor: 'unset !important',
							},
						},
					},
				}),

				MUIDataTableBodyCell: {
					root: {
						cursor: 'pointer',
					},
				},

				MuiListItemText: {
					root: {
						whiteSpace: 'pre-wrap',
					},
				},
				MuiIconButton: {
					root: {
						borderRadius: '8px',
					},
				},
				MuiAvatar: {
					root: {
						borderRadius: '8px',
					},
				},
				MuiChip: {
					root: {
						borderRadius: '8px',
					},
				},
			},
		})
	);

export const useStyles = makeStyles((theme) => {
	return {
		'@global': {
			body: {
				margin: '0px',
			},
			'.success-alteri-color': {
				backgroundColor: '#00A99D !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#00A99D !important',
				},
			},
			'.info-alteri-color': {
				backgroundColor: '#54698C !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#54698C !important',
				},
			},
			'.danger-alteri-color': {
				backgroundColor: '#BF0603 !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#BF0603 !important',
				},
			},
			'.default-alteri-color': {
				backgroundColor: '#e0e0e0 !important',
				color: 'black !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#d5d5d5 !important',
				},
			},
			'.icon-danger-alteri-color': {
				color: '#BF0603 !important',
				'&:hover': {
					opacity: '0.8 !important',
					color: '#BF0603 !important',
				},
			},
			'.warning-alteri-color': {
				backgroundColor: '#F4D58D !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#F4D58D !important',
				},
			},
			'.icon-warning-alteri-color': {
				color: '#F4D58D !important',
				'&:hover': {
					opacity: '0.8 !important',
					color: '#F4D58D !important',
				},
			},
			'.flexGrow': {
				flexGrow: '1',
			},
			'.iconSmall': {
				fontSize: 20,
			},
			'.leftIcon': {
				marginRight: theme.spacing(1),
			},
			'.rightIcon': {
				marginLeft: theme.spacing(1),
			},
			'.hidden': {
				display: 'none',
			},
			'.divider': {
				margin: theme.spacing(2, 0),
			},
			'.inline': {
				display: 'inline',
			},
			'.disabled-link-cursor': {
				cursor: 'not-allowed',
			},
			...classes(theme),
		},
	};
});
