/**Ticket status */
const REFUSED = -10;
const FAILED = -5;
const CREATED = 0;
const PROCESSED = 10;
const PENDING = 15;
const INVITE = 16;
const PAYED = 20;
const REJECTED = 30;
const WAITING = 40;
const ACTIVE = 50;
const CLOSED = 100;
const ARCHIVED = 110;

/**Ticket types */
const TICKET = 'ticket';
const FOLLOWUP = 'followup';
const TYPE_INVITE = 'invite';

export default {
	STATUS: {
		REFUSED,
		FAILED,
		CREATED,
		PROCESSED,
		PENDING,
		INVITE,
		PAYED,
		REJECTED,
		WAITING,
		ACTIVE,
		CLOSED,
		ARCHIVED
	},
	TYPES: {
		TICKET,
		FOLLOWUP,
		TYPE_INVITE,
	},
	SCOPES: {
		NAVIGATION: {
			OVERVIEW: 'tickets.navigation.overview',
			MESSAGES: 'tickets.navigation.messages',
			VIDEO: 'tickets.navigation.video',
			MEETINGS: 'tickets.navigation.meetings',
			ALERTS: 'tickets.navigation.alerts',
			QUESTIONNAIRES: 'tickets.navigation.questionnaires',
			SHARE_SELFCARE: 'tickets.navigation.share_selfcare',
			FILES: 'tickets.navigation.files',
			CERTIFICATES: 'tickets.navigation.certificates',
			FOLLOWUPS: 'tickets.navigation.followups',
			APPOINTMENTS: 'tickets.navigation.appointments',
			REJECT: 'tickets.navigation.reject',
			NOTES: 'tickets.navigation.notes',
			REFERENCE: 'tickets.navigation.reference',
			DIAGNOSES: 'tickets.navigation.diagnoses',
		},
		OVERVIEW: {
			CARETEAM: 'tickets.overview.careteam',
			HISTORY: 'tickets.overview.history',
			ALERTS: 'tickets.overview.alerts',
			QUESTIONNAIRES: 'tickets.overview.questionnaires',
			APPOINTMENTS: 'tickets.overview.appointments',
			FOLLOWUPS: 'tickets.overview.followups',
			CERTIFICATES: 'tickets.overview.certificates',
			DIAGNOSES: 'tickets.overview.diagnoses',
			NOTES: 'tickets.overview.notes',
			MEETINGS: 'tickets.overview.meetings',
			FILES: 'tickets.overview.files',
			TASKS: 'tickets.overview.tasks',
			CASE_ID: 'ticket.overview.case_id',
		},
		MESSAGES: {
			WRITE: 'tickets.messages.write',
			UPLOAD_FILE: 'tickets.messages.upload_file',
			MENTION: 'tickets.messages.mention',
			links: 'tickets.messages.links',
		},
		MANAGE: {
			SAVE: 'tickets.manage.save',
			CLOSE: 'tickets.manage.close',
			SUMMARY: 'tickets.manage.summary',
			ACCESS: 'tickets.manage.access',
			VIEW: 'tickets.manage.view',
		},
		QUESTIONNAIRES: {
			ANSWER: 'tickets.questionnaires.answer',
			DETAILS: 'tickets.questionnaires.details',
			DISPLAY: 'tickets.questionnaires.display',
			STATISTICS: 'tickets.questionnaires.statistics',
		},
		CARETEAM: {
			ADD: 'tickets.careteam.add',
			REMOVE: 'tickets.careteam.remove',
			ADD_EXTERNAL: 'tickets.careteam.add_external',
			EDIT: 'tickets.careteam.edit',
			ASSIGN_RESPONSIBLE: 'tickets.careteam.assign_responsible',
			CHANGE_RESPONSIBLE: 'tickets.careteam.change_responsible',
			DETAILS: 'tickets.careteam.details',
		},
		VIDEO: {
			START_VIDEO_SESSION: 'ticket.video.start_video_session'
		},
		FILES: {
			NOTIFY_DOWNLOAD: 'ticket.files.notify_download',
			DETAILS: 'tickets.files.details',
			MARKDOWNLOAD: 'tickets.files.markdownload',
		}
	},
};
