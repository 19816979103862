
import React from 'react';
import { Field } from 'redux-form';
import { Typography, Grid, FormControl, FormHelperText, Slider } from '@material-ui/core';

import {  RenderTextField } from './';

function RenderSlider({ input: { onChange, value }, meta: { valid, invalid, touched, error }, ...custom }) {
    return (
        <div>
            <Typography
                id="continuous-slider"
                gutterBottom
                className={custom.inputdata.validate && touched && invalid ? 'generic-form-field-required-error' : ''}
            >
                {custom.inputdata.label}
            </Typography>
            <div>
                <Grid container alignItems="center"  justifyContent="flex-end">
                    <Grid item xs={12} sm={10} className={'generic-form-content-form-field-slider'}>
                        <FormControl error={custom.inputdata.validate ? touched && invalid : false} className={'generic-form-control'}>
                            <Slider
                                id={custom.inputdata.id}
                                name={custom.inputdata.name}
                                label={custom.inputdata.label}
                                step={custom.inputdata.step}
                                valueLabelDisplay={'auto'}
                                onChange={(event, value) => {
                                    if (typeof custom.inputdata.onChange !== 'undefined') {
                                        custom.inputdata.onChange(value);
                                    }
                                    onChange(value);
                                }}
                                value={value === '' ? null : value}
                                max={custom.inputdata.max}
                                marks={[
                                    {
                                        value: 0,
                                        label: '0 ' + custom.inputdata.units,
                                    },
                                    {
                                        value: custom.inputdata.max,
                                        // label: custom.inputdata.max + ' ' + custom.inputdata.units,
                                        label: custom.inputdata.max,
                                    },
                                ]}
                                disabled={custom.inputdata.readonly}
                            />
                            {custom.inputdata.validate && touched && invalid ? (
                                <FormHelperText id="standard-weight-helper-text">{custom.requiredLabel}</FormHelperText>
                            ) : null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}  className={'generic-form-content-form-field-slider-suffix-left-padding'}>
                        {custom.inputdata.suffix ? (
                            <Field name={custom.inputdata.suffix.name} component={RenderTextField} inputdata={custom.inputdata.suffix} />
                        ) : (
                                <Typography align={'center'}>
                                    {value === 0 || value === '' ? 0 : value}
                                    {' ' + custom.inputdata.units}
                                </Typography>
                            )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default RenderSlider;