import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';
import TICKET from '../../helpers/ticket';

function StatusChip({ status, type, translate }) {
	switch (parseInt(status)) {
		case TICKET.STATUS.REFUSED:
			return <Chip className="tickets-chipstatus-refused" label={translate('generic_status_refused')} />;

		case TICKET.STATUS.FAILED:
			return <Chip className="tickets-chipstatus-failed" label={translate('generic_status_failed')} />;

		case TICKET.STATUS.CREATED:
			return <Chip className="tickets-chipstatus-created" label={translate('generic_status_created')} />;

		case TICKET.STATUS.PROCESSED:
			return <Chip className="tickets-chipstatus-processed" label={translate('generic_status_processed')} />;

		case TICKET.STATUS.PENDING:
			if (type == TICKET.TYPES.TYPE_INVITE) {
				return <Chip className="tickets-chipstatus-invite" label={translate('generic_status_invite')} />;
			} else if (type == TICKET.TYPES.FOLLOWUP) {
				return <Chip className="tickets-chipstatus-followup" label={translate('generic_status_followup')} />;
			} else {
				return <Chip className="tickets-chipstatus-pending" label={translate('generic_status_pending')} />;
			}
		case TICKET.STATUS.INVITE:
			return <Chip className="tickets-chipstatus-invite" label={translate('generic_status_invite')} />;

		case TICKET.STATUS.PAYED:
			return <Chip className="tickets-chipstatus-payed" label={translate('generic_status_payed')} />;

		case TICKET.STATUS.REJECTED:
			return <Chip className="tickets-chipstatus-rejected" label={translate('generic_status_rejected')} />;

		case TICKET.STATUS.WAITING:
			return <Chip className="tickets-chipstatus-waiting" label={translate('generic_status_waiting')} />;

		case TICKET.STATUS.ACTIVE:
			return <Chip className="tickets-chipstatus-active" label={translate('generic_status_active')} />;

		case TICKET.STATUS.CLOSED:
			return <Chip className="tickets-chipstatus-closed" label={translate('generic_status_closed')} />;
		
		case TICKET.STATUS.ARCHIVED:
			return <Chip className="tickets-chipstatus-archived" label={translate('generic_status_archived')} />;

		default:
			return null;
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(StatusChip);
