import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import RenderNotificationsBell from './NotificationsBell';

import { Badge, IconButton } from '@material-ui/core';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import SocketProvider from '../Chat/SocketProvider';
import { NOTIFICATIONS } from '../../helpers/webSocketChannels';
import { SocketContext } from '../Chat/SocketProvider';
import { filterChannelMessagesAction } from '../Chat/actions/websocketsActions';
import { displayNotificationsModalAction } from './actions/genericActions';

class Notifications extends Component {
	handleFilterMessages = (data) => {
		return data.type === 'notification' && data.link_type !== 'read'; //Check this
	};

	handleSendMessage = (data) => {
		if (data.type === 'notification' && data.unread == '1') {
			var bellList = document.getElementById('listBell_' + data.ticket_id);
			var bellNav = document.getElementById('navBell');

			if (bellList !== null) {
				bellList.classList.remove('notification-bell-hidden');
				bellList.classList.add('notify');
				bellNav.classList.add('notify');
				setTimeout(() => {
					bellList.classList.remove('notify');
					bellNav.classList.remove('notify');
				}, 500);
			}
		} else if ((data.type === 'readmark' || data.type === 'downloadmark') && data.ticket_id) {
			var bellList = document.getElementById('listBell_' + data.ticket_id);

			if (bellList !== null) {
				bellList.classList.add('notification-bell-hidden');
			}
		}
	};

	render() {
		return (
			<SocketProvider
				channel_name={NOTIFICATIONS}
				channel={typeof this.props.channel !== 'undefined' ? this.props.channel.name : ''}
				handleSendMessage={this.handleSendMessage}
				handleFilterMessages={this.handleFilterMessages}
				pushMessageToTop={true}
			>
				<IconButton
					size={'small'}
					color="primary"
					aria-controls={this.props.notificationsId}
					aria-haspopup="true"
					onClick={() => this.props.displayNotificationsModalAction(true)}
					// disabled={this.props.messages.length === 0}
				>
					<Badge badgeContent={this.props.messages.length} color="error">
						{/* <NotificationsIcon /> */}
						<div id={'navBell'} className="notification-nav-bell"></div>
					</Badge>
				</IconButton>
			</SocketProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	messages: state.websockets[NOTIFICATIONS].messages.filter((message) => message.unread == '1'),
	whoami: state.users.whoami,
	config: state.config,
	channel: state.users.whoami.channel,
	translate: getTranslate(state.localize),
	timezone: state.users.whoami.timezone,
});

export default connect(mapStateToProps, { filterChannelMessagesAction, displayNotificationsModalAction })(Notifications);
